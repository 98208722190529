import React from "react";
import "./footer.css";
import github from "../../assets/github.png";
import instagram from "../../assets/instagram.png";
import linkedin from "../../assets/linkedin.png";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <div className="footer_container">
        <hr />
      <div className="footer">
        <div className="social">
        <img src={github} alt="" />
        <img src={instagram} alt="" />
        <img src={linkedin} alt="" />
        </div>
      <div>
      <img src={logo} alt="" className="logo_f" />
    </div>
    </div>
    <div className="blur footer_blur_1"></div>
    <div className="blur footer_blur_2"></div>
    </div>
  );
};

export default Footer;
