import './App.css';
import Hero from './components/Hero/Hero.jsx';
import Program from './components/Programs/program.jsx';
import Reasons from './components/Reasons/Reasons.jsx';
import Plans from './components/Plans/Plans.jsx';
import Testemonials from './components/Testemonials/Testimonials.jsx';
import Join from './components/Join/Join.jsx';
import Footer from './components/Footer/Footer.jsx';
function App() {
  return (
    <div className="App">
      <Hero/>
      <Program/>
      <Reasons/>
      <Plans/>
      <Testemonials/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
